<template>
  <div class="single_box" :id="dataChange(singleInfo).id">
    <!-- 左侧头像 -->
    <div class="left_box">
      <el-tag class="tag" size="mini" type="success" v-if="tag !== null">
        {{ tag.tag }}
      </el-tag>
      <img
        v-if="dataChange(singleInfo).author.avatar"
        :src="dataChange(singleInfo).author.avatar"
        alt=""
      />
      <img v-else src="../../../assets/imgs/mrtx.png" alt="" />
    </div>
    <!-- 右侧部分 -->
    <div
      class="right_box"
      :class="msgId == dataChange(singleInfo).id ? 'single-select-class' : ''"
    >
      <div class="top_1">
        <el-popover placement="right" width="300" trigger="hover">
          <el-descriptions title="用户信息" :column="1" size="mini" border>
            <el-descriptions-item>
              <template slot="label">
                头像
              </template>
              <div>
                <img
                  v-if="dataChange(singleInfo).author.avatar"
                  :src="dataChange(singleInfo).author.avatar"
                  alt=""
                />
                <img v-else src="../../../assets/imgs/mrtx.png" alt="" />
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                ID
              </template>
              <div>
                {{
                  dataChange(singleInfo).author
                    ? dataChange(singleInfo).author.id
                    : ''
                }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                姓名
              </template>
              <div>
                {{
                  dataChange(singleInfo).author
                    ? dataChange(singleInfo).author.name
                    : ''
                }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                电话
              </template>
              <div>
                {{
                  dataChange(singleInfo).author
                    ? dataChange(singleInfo).author.phone
                    : ''
                }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                标记
              </template>
              <div v-if="dataChange(singleInfo).author.tags">
                <el-tag
                  class="item-tag"
                  size="mini"
                  type="success"
                  v-for="(item, i) in dataChange(singleInfo).author.tags"
                  :key="i"
                >
                  {{ item.tag }}
                </el-tag>
              </div>
            </el-descriptions-item>
          </el-descriptions>
          <div
            slot="reference"
            class="user-id"
            @click="toUserPage(dataChange(singleInfo).author.id)"
          >
            {{
              dataChange(singleInfo).author !== null
                ? dataChange(singleInfo).author.name
                  ? dataChange(singleInfo).author.name
                  : '[未知用户]'
                : '[未知用户]'
            }}
          </div>
        </el-popover>
        <!-- <div
          class="user-id"   
          v-if="dataChange(singleInfo).author !== null"
          @click="toUserPage(dataChange(singleInfo).author.id)"
        >
          ID:{{ dataChange(singleInfo).author.id }}
        </div> -->
        <div>{{ dataChange(singleInfo).send_time }}</div>
        <div v-if="dataChange(singleInfo).tags" class="tags-box">
          <el-tag
            class="item-tag"
            size="medium"
            type="success"
            v-for="(item, i) in dataChange(singleInfo).tags"
            :key="i"
            :closable="true"
            @close="closeTagFun(item)"
          >
            {{ item.tag }}
          </el-tag>
          <tagComponents size="mini" @updateTagFun="updateTagFun" />
        </div>
        <img
          @click="showDia(dataChange(singleInfo).tags)"
          src="@/assets/imgs/mark.png"
          title="打标签"
          alt=""
        />
        <img
          v-if="detailType"
          style="margin-left: 10px;"
          @click="toPage(dataChange(singleInfo))"
          src="@/assets/imgs/detail.png"
          title="详情"
          alt=""
        />
        <el-button
          @click="handleDel(id)"
          v-if="$route.path === '/tagged/messageTag'"
          type="text"
        >
          取消标记
        </el-button>
      </div>
      <div class="content_box">
        <div>
          <div>
            <div class="content-text">
              <img
                v-if="dataChange(singleInfo).type == 'image'"
                :src="
                  dataChange(singleInfo).media
                    ? dataChange(singleInfo).media
                    : require('@/assets/imgs/i25.png')
                "
                alt=""
                @click="mediaFun"
              />
              <img
                v-else-if="dataChange(singleInfo).type == 'ptt'"
                src="@/assets/imgs/i23.png"
                alt=""
                @click="mediaFun"
              />
              <img
                v-else-if="dataChange(singleInfo).type == 'video'"
                src="@/assets/imgs/i24.png"
                alt=""
                @click="mediaFun"
              />
              <div
                class="text"
                v-html="
                  dataChange(singleInfo).highlighted_text ||
                    dataChange(singleInfo).text
                "
              ></div>
            </div>

            <!-- 群组信息 -->
            <div style="text-align: right;margin-top: 5px;">
              群组:
              <el-popover placement="left" width="400" trigger="hover">
                <el-descriptions
                  title="群组信息信息"
                  :column="1"
                  size="mini"
                  border
                >
                  <el-descriptions-item>
                    <template slot="label">
                      群头像
                    </template>
                    <div>
                      <img
                        v-if="dataChange(singleInfo).group.avatar"
                        :src="dataChange(singleInfo).group.avatar"
                        alt=""
                      />
                      <img v-else src="../../../assets/imgs/i9.png" alt="" />
                    </div>
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">
                      ID
                    </template>
                    <div>
                      {{
                        dataChange(singleInfo).group
                          ? dataChange(singleInfo).group.id
                          : ''
                      }}
                    </div>
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">
                      群名称
                    </template>
                    <div>
                      {{
                        dataChange(singleInfo).group
                          ? dataChange(singleInfo).group.name
                          : ''
                      }}
                    </div>
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">
                      群标记
                    </template>
                    <div v-if="dataChange(singleInfo).group.tags">
                      <el-tag
                        class="item-tag"
                        size="mini"
                        type="success"
                        v-for="(item, i) in dataChange(singleInfo).group.tags"
                        :key="i"
                      >
                        {{ item.tag }}
                      </el-tag>
                    </div>
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">
                      群描述
                    </template>
                    <div>
                      {{
                        dataChange(singleInfo).group
                          ? dataChange(singleInfo).group.desc
                          : ''
                      }}
                    </div>
                  </el-descriptions-item>
                </el-descriptions>

                <span
                  slot="reference"
                  v-if="dataChange(singleInfo).group.id"
                  class="user-id"
                  @click="toGroupPage(singleInfo.group.id)"
                >
                  {{
                    dataChange(singleInfo).group.name
                      ? dataChange(singleInfo).group.name
                      : dataChange(singleInfo).group.id
                  }}
                </span>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 标签弹框 -->
    <el-dialog
      title="添加标签"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <LabelForm
        ref="LabelFormRef"
        @reciverLabel="reciverLabel"
        v-if="dialogVisible"
        @updateFun="labelFormUpdateFun"
        tagType="msg"
        :singleInfo="singleInfo"
      />
    </el-dialog>
    <!-- 媒体弹框 -->
    <el-dialog
      title="媒体"
      :visible.sync="dialogMediaVisible"
      width="30%"
      :before-close="handleCloseMedia"
    >
      <div class="media-dialog">
        <img
          v-if="dataChange(singleInfo).type == 'image'"
          @click="handleImg"
          :src="
            dataChange(singleInfo).media
              ? dataChange(singleInfo).media
              : require('@/assets/imgs/i25.png')
          "
          alt=""
        />
        <audio controls v-else-if="dataChange(singleInfo).type == 'ptt'">
          <source :src="dataChange(singleInfo).media" type="audio/ogg" />
          <source :src="dataChange(singleInfo).media" type="audio/mpeg" />
        </audio>
        <video
          v-else-if="dataChange(singleInfo).type == 'video'"
          style="width:100%"
          controls
          :src="dataChange(singleInfo).media"
        ></video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import LabelForm from '@/components/LabelForm.vue'
import { addUserApi, addMsgApi, addGroupApi } from '@/api/tag'
import { addMessagesLabel } from '@/api/whatsApp'
import { delMessageTag } from '@/api/tagged'
import { delGroupApi, delUserApi, delMsgApi } from '@/api/tag'
export default {
  name: 'SingleMessage',
  props: {
    singleInfo: {
      type: Object,
      default: {}
    },
    tag: {
      type: Object,
      default: null
    },
    tagType: {
      type: String,
      default: ''
    },
    id: {
      type: [String, Number],
      default: null
    },
    detailType: {
      type: Boolean,
      default: false
    },
    msgId: {
      type: String,
      default: null
    }
  },
  components: {
    LabelForm
  },
  data() {
    return {
      dialogVisible: false,
      enlarge: false, // 放大缩小图片
      // 媒体
      dialogMediaVisible: false, //
      mediaInfo: {}
    }
  },
  created() {},
  methods: {
    handleClose() {
      this.dialogVisible = false
    },
    showDia(tags) {
      this.dialogVisible = true
      this.$nextTick(() => {
        if (tags && tags.length > 0) {
          this.$refs.LabelFormRef.ruleForm.tags = tags.map(e => {
            return e.id
          })
        }
      })
    },
    // 发送请求添加标签
    async sendResult(val) {
      const data = {
        ids: val.tags
      }
      await addMessagesLabel(this.singleInfo.id, data)
      this.$emit('updateFun')
      this.dialogVisible = val.flag
    },
    // 收到选中的标签
    reciverLabel(val) {
      // 收到值发送请求
      if (val.tags.length !== 0) {
        this.sendResult(val)
      } else {
        // 否则关闭弹框
        this.dialogVisible = val.flag
      }
    },
    handleImg() {
      this.enlarge = !this.enlarge
    },
    // 取消标记
    handleDel(id) {
      this.$confirm('此操作将取消该标记, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await delMessageTag(id)
          this.$emit('reciveVal', true)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 跳转详情
    toPage(singleInfo) {
      if (!singleInfo.group) return this.$message.warning('未检索到群组数据')
      this.$router.push({
        path: '/group/detail/' + singleInfo.group.id,
        query: {
          send_time: singleInfo.send_time,
          msgId: singleInfo.id
        }
        // path: '/group/detail/' + '120363128342913206@g.us',
        // query: {
        //   send_time: '2023-08-20 18:03:20',
        //   msgId: '7719FA0E116075EA481A696B3BBED73C'
        // }
      })
    },
    // 跳转用户
    toUserPage(id) {
      this.$router.push({
        path: '/user/detail/' + id
      })
    },
    // 跳转群组
    toGroupPage(id) {
      this.$router.push('/group/detail/' + id)
    },
    dataChange(obj) {
      if (!obj.author) {
        obj.author = {}
      }
      if (!obj.group) {
        obj.group = {}
      }
      if (!obj.raw) {
        obj.raw = {}
      }

      return obj
    },
    // 媒体弹框关闭
    handleCloseMedia() {
      this.dialogMediaVisible = false
    },
    mediaFun() {
      if (!this.dataChange(this.singleInfo).media) {
        return this.$message('暂无媒体链接！')
      }
      this.dialogMediaVisible = true
    },
    // 删除tag标签
    closeTagFun(row) {
      this.$confirm('确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          if (this.tagType === 'msg') {
            delMsgApi(this.singleInfo.id, row.id).then(res => {
              this.$message({
                type: 'success',
                message: '标签已删除！'
              })
              this.$emit('updateFun')
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    labelFormUpdateFun() {
      this.$emit('updateFun')
    },
    updateTagFun(res) {
      //
      if (this.tagType === 'msg') {
        addMsgApi({
          tag: res.id,
          message: this.singleInfo.id
        })
          .then(res => {
            this.$message({
              type: 'success',
              message: '消息已标记！'
            })
            this.$emit('updateFun')
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '消息标记失败！'
            })
          })
      } else if (this.tagType === 'user') {
        addUserApi({
          tag: res.id,
          user: this.singleInfo.id
        })
          .then(res => {
            this.$message({
              type: 'success',
              message: '用户已标记！'
            })
            this.$emit('updateFun')
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '用户标记失败！'
            })
          })
      } else if (this.tagType === 'group') {
        addGroupApi({
          tag: res.id,
          group: this.singleInfo.id
        })
          .then(res => {
            this.$message({
              type: 'success',
              message: '群组已标记！'
            })
            this.$emit('updateFun')
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '群组标记失败！'
            })
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.single_box {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #f2f4f8;
  margin-bottom: 10px;
  padding: 13px 10px;
  overflow: hidden;
  .el-button {
    padding: 0 !important;
    margin-left: 10px;
  }

  .left_box {
    display: flex;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .tag {
      margin: 10px 10px 0 0;
    }
  }
  .right_box {
    width: calc(100% - 50px);
    font-size: 12px;
    color: #666;
    margin-left: 15px;

    .top_1 {
      display: flex;
      align-items: center;
      div {
        margin-right: 10px;
      }
      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
    .content_box {
      padding-top: 5px;
      .content-text {
        display: flex;
        img {
          width: 80px;
          height: 80px;
          object-fit: cover;
          margin-right: 20px;
          cursor: pointer;
          &:hover {
            transform: scale(0.96);
          }
        }
        .text {
          padding: 10px 0;
        }
      }
    }
  }
  .user-id {
    cursor: pointer;
    font-weight: 600;
    color: #000;
    font-size: 14px;
    &:hover {
      color: blue;
    }
  }
  .tags-box {
    display: flex;

    .item-tag {
      margin-right: 5px;
    }
  }
}
.single-select-class {
  color: #f34d4d !important;
  font-weight: 600;
}
.media-dialog {
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    height: auto;
    object-fit: scale-down;
  }
}
</style>
