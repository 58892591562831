import { messages1_Api, messages2_Api, messages3_Api } from '@/api/whatsApp'
export const requestMixin = {
  data() {
    return {}
  },
  computed: {},

  methods: {
    // 匹配模式(旧接口)
    getMsg1() {
      return new Promise((resolve, reject) => {
        this.listLoading = true
        let obj = { ...this.queryForm }
        delete obj.radio
        messages1_Api(obj)
          .then(res => {
            this.count = res.count
            this.list = res.items
            this.listLoading = false
            resolve(res)
          })
          .catch(error => {
            this.$message.error(error)
            this.listLoading = false
          })
      })
    },

    // 分词模式
    getMsg2() {
      return new Promise((resolve, reject) => {
        this.listLoading = true
        let obj = { ...this.queryForm }
        delete obj.radio
        messages2_Api(obj)
          .then(res => {
            this.count = res.count
            this.list = res.items
            this.listLoading = false
            resolve(res)
          })
          .catch(error => {
            this.$message.error(error)
            this.listLoading = false
          })
      })
    },
    // 短句模式
    getMsg3() {
      return new Promise((resolve, reject) => {
        this.listLoading = true
        let obj = { ...this.queryForm }
        delete obj.radio
        messages3_Api(obj)
          .then(res => {
            this.count = res.count
            this.list = res.items
            this.listLoading = false
            resolve(res)
          })
          .catch(error => {
            this.$message.error(error)
            this.listLoading = false
          })
      })
    },
    // =======================线索数据========================
    getMsg1Scoll() {
      return new Promise((resolve, reject) => {
        this.listLoading = true
        let obj = { ...this.queryForm }
        delete obj.radio
        this.disabled = true
        messages1_Api(obj)
          .then(res => {
            if (res.items.length < res.page_size) {
              this.noMore = true
            } else {
              this.page_index = this.page_index + 1
              this.disabled = false
            }
            this.list = [...this.list, ...res.items]

            this.count = res.count
            this.listLoading = false
            resolve(res)
          })
          .catch(error => {
            this.$message.error(error)
            this.listLoading = false
          })
      })
    },
    getMsg2Scoll() {
      return new Promise((resolve, reject) => {
        this.listLoading = true
        let obj = { ...this.queryForm }
        delete obj.radio
        this.disabled = true
        messages2_Api(obj)
          .then(res => {
            if (res.items.length < res.page_size) {
              this.noMore = true
            } else {
              this.page_index = this.page_index + 1
              this.disabled = false
            }
            this.list = [...this.list, ...res.items]

            this.count = res.count
            this.listLoading = false
            resolve(res)
          })
          .catch(error => {
            this.$message.error(error)
            this.listLoading = false
          })
      })
    },
    getMsg3Scoll() {
      return new Promise((resolve, reject) => {
        this.listLoading = true
        let obj = { ...this.queryForm }
        delete obj.radio
        this.disabled = true
        messages3_Api(obj)
          .then(res => {
            if (res.items.length < res.page_size) {
              this.noMore = true
            } else {
              this.page_index = this.page_index + 1
              this.disabled = false
            }
            this.list = [...this.list, ...res.items]

            this.count = res.count
            this.listLoading = false
            resolve(res)
          })
          .catch(error => {
            this.$message.error(error)
            this.listLoading = false
          })
      })
    },
    // =======================用户聊天详情========================
    getMsg1_msg_Scoll() {
      return new Promise((resolve, reject) => {
        this.listLoading = true
        let obj = { ...this.queryForm }
        delete obj.radio

        messages1_Api(obj)
          .then(res => {
            if (res.items.length < res.page_size) {
              this.disabled2 = true
            } else {
              this.pageIndex = this.pageIndex + 1
              this.disabled2 = false
            }
            this.messageList = [...this.messageList, ...res.items]
            this.total2 = res.count
            this.contentLoading = false
            resolve(res)
          })
          .catch(error => {
            this.$message.error(error)
            this.contentLoading = false
          })
      })
    },
    getMsg2_msg_Scoll() {
      return new Promise((resolve, reject) => {
        this.listLoading = true
        let obj = { ...this.queryForm }

        delete obj.radio
        this.listLoading = true
        messages2_Api(obj)
          .then(res => {
            if (res.items.length < res.page_size) {
              this.disabled2 = true
            } else {
              this.pageIndex = this.pageIndex + 1
              this.disabled2 = false
            }
            this.messageList = [...this.messageList, ...res.items]
            this.total2 = res.count
            this.contentLoading = false
            resolve(res)
          })
          .catch(error => {
            this.$message.error(error)
            this.listLoading = false
          })
      })
    },
    getMsg3_msg_Scoll() {
      return new Promise((resolve, reject) => {
        this.listLoading = true
        let obj = { ...this.queryForm }
        delete obj.radio
        this.listLoading = true

        messages3_Api(obj)
          .then(res => {
            if (res.items.length < res.page_size) {
              this.disabled2 = true
            } else {
              this.pageIndex = this.pageIndex + 1
              this.disabled2 = false
            }
            this.messageList = [...this.messageList, ...res.items]
            this.total2 = res.count
            this.contentLoading = false
            resolve(res)
          })
          .catch(error => {
            this.$message.error(error)
            this.listLoading = false
          })
      })
    },
    // =======================群组聊天详情========================
    getMsg1_group_Scoll() {
      return new Promise((resolve, reject) => {
        this.textLoading = true
        this.disabled2 = true
        messages1_Api(this.queryForm)
          .then(res => {
            if (res.items.length < res.page_size) {
              this.noMore2 = true
            } else {
              this.pageIndex = this.pageIndex + 1
              this.disabled2 = false
            }

            if (ordering == '-send_time') {
              res.items.forEach(item => {
                if (
                  !this.messageList.some(ele => {
                    return item.id == ele.id
                  })
                ) {
                  this.messageList.unshift(item)
                }
              })
            } else {
              res.items.forEach(item => {
                if (
                  !this.messageList.some(ele => {
                    return item.id == ele.id
                  })
                ) {
                  this.messageList.push(item)
                }
              })
            }

            this.total2 = res.count
            this.textLoading = false
            resolve(res.items)
          })
          .catch(error => {
            this.textLoading = false
            this.$message.error(error)
          })
      })
    },
    getMsg2_group_Scoll() {
      return new Promise((resolve, reject) => {
        this.textLoading = true
        this.disabled2 = true
        messages2_Api(this.queryForm).then(res => {
          if (res.items.length < res.page_size) {
            this.noMore2 = true
          } else {
            this.pageIndex = this.pageIndex + 1
            this.disabled2 = false
          }

          if (ordering == '-send_time') {
            res.items.forEach(item => {
              if (
                !this.messageList.some(ele => {
                  return item.id == ele.id
                })
              ) {
                this.messageList.unshift(item)
              }
            })
          } else {
            res.items.forEach(item => {
              if (
                !this.messageList.some(ele => {
                  return item.id == ele.id
                })
              ) {
                this.messageList.push(item)
              }
            })
          }

          this.total2 = res.count
          this.textLoading = false
          resolve(res.items)
        })
      }).catch(error => {
        this.textLoading = false
        this.$message.error(error)
      })
    },
    getMsg3_group_Scoll() {
      return new Promise((resolve, reject) => {
        this.textLoading = true
        this.disabled2 = true
        messages3_Api(this.queryForm)
          .then(res => {
            if (res.items.length < res.page_size) {
              this.noMore2 = true
            } else {
              this.pageIndex = this.pageIndex + 1
              this.disabled2 = false
            }

            if (ordering == '-send_time') {
              res.items.forEach(item => {
                if (
                  !this.messageList.some(ele => {
                    return item.id == ele.id
                  })
                ) {
                  this.messageList.unshift(item)
                }
              })
            } else {
              res.items.forEach(item => {
                if (
                  !this.messageList.some(ele => {
                    return item.id == ele.id
                  })
                ) {
                  this.messageList.push(item)
                }
              })
            }

            this.total2 = res.count
            this.textLoading = false
            resolve(res.items)
          })
          .catch(error => {
            this.textLoading = false
            this.$message.error(error)
          })
      })
    }
  }
}
