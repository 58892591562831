function scrollTopFun(dom, type, topNumber) {
  let domHtml = document.getElementById(dom)
  if (!domHtml) return console.log('dom不存在')
  if (type === 'get') {
    return domHtml.scrollTop
  } else if (type === 'set') {
    domHtml.scrollTop = topNumber
  }
}
export { scrollTopFun }
